import React, { useEffect, useState } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import './especialidade.scss'
import trackEvent from "../analyticsHelpers"
import Sabrina from '../images/Sabrina.jpeg'
import Ellipse1 from '../images/ellipse-specialist.png'
import Ellipse2 from '../images/ellipse-specialist2.png'
import Ellipse3 from '../images/ellipse-specialist3.png'
import EllipseMob from '../images/ellipse-specialist-mob.png'
import Ellipse2Mob from '../images/ellipse-specialist2-mob.png'
import RegularButton from "../components/atoms/buttons/RegularButton"
import Collapsible from "../components/collapsible"
import { goToApp } from "../utils"
import EspecialidadeImg from '../images/topo-fisio-pelvica.png'

const FisioterapeutaPelvica = () => {
  useEffect(() => {
    trackEvent("sitenovo-fisioterapeuta-pelvica-visualizou-tela")
  }, [])

  return (
    <Layout>
      <GatsbySeo
        title='Theia | Fisioterapeuta Pélvica'
        description='.'
        language='pt-br'
        canonical='https://blog.theia.com.br/fisioterapeuta-pelvica'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/fisioterapeuta-pelvica',
          title: 'Theia | Fisioterapeuta Pelvica',
          description: 'Fisioterapeutas pélvicas garantem sua saúde pélvica durante a gravidez e o pós-parto ',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Fisioterapeuta Pélvica'
        }, {
          name: 'dc:description',
          content: 'Fisioterapeutas pélvicas garantem sua saúde pélvica durante a gravidez e o pós-parto'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Fisioterapeutas pélvicas garantem sua saúde pélvica durante a gravidez e o pós-parto',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, Fisioterapeuta Pelvica, psicologos, fisioterapeuta pelvico, preparador fisico, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
        ]}
      />
      <section className="especialidade-content section-1 grid-wrapper-section items-center lg:items-start">
        <img src={Ellipse3} alt="" className="ellipse3" />
        <div className="item1">
          <h1 className="text-dsTitle2 lg:text-dsTitle1 text-primary main-title text-center lg:text-left">
            Conheça nosso time de fisioterapeuta pélvica
          </h1>
        </div>
        <div className="item2 self-end internas relative">
          <img src={Ellipse1} alt="" className="ellipse1 hidden md:block" />
          <img src={Ellipse2} alt="" className="ellipse2 hidden md:block" />
          <img src={EllipseMob} alt="" className="ellipse5 block md:hidden" />
          <img src={Ellipse2Mob} alt="" className="ellipse4 block md:hidden" />
          <img src={EspecialidadeImg} alt="" style={{ width: '540px', float: 'right' }} className="relative main-specialty-img" />
        </div>
      </section>

      <section className="wrapper-section-row time-especialidade" id="sabrina">
        
        <div className="item2 mb-3 lg:mb-0">
          <img src={Sabrina} alt="Sabrina Caixeta de Oliveira" style={{ width: '360px', borderRadius: '50%'}} />
        </div>
        <div className="item3">
          <div className="flex justify-between">
            <div>
              <h3 className="text-titleMedium lg:text-dsTitle2 text-primary text-center lg:text-left">Sabrina Caixeta de Oliveira</h3>
              <p className="text-dsTextSmal text-textSecondary mt-2 mb-6 text-center lg:text-left"><span>Fisioterapeuta Pélvica</span></p>
            </div>
            <RegularButton
              onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-fisio-pelvica-sabrina-caixeta-botao-agendar-consulta')}
              label="Agendar consulta"
              variant="secondary"
              width="185px"
            />
          </div>
          <p className="time-text">
            Oi, eu sou a Sabrina. Sempre me interessei pela área de biológicas e sempre tive certeza de que seguiria uma formação na área da saúde. Mas a certeza maior, de estar no caminho certo dentro da fisioterapia, veio justamente quando iniciei minha jornada na fisioterapia obstétrica.
          </p>
          <Collapsible open={false} header='Ver Mais' openedHeader='Ver menos' variant="secondary" changeHeader>
          <div>
            <p className="time-text mt-6 mb-6">
              Sou instrutora de Pilates e Stott Pilates. E quase dez anos de experiência em fisio pélvica. Atender gestantes faz minha vida profissional mais feliz, mas o maior ganho é em minha vida pessoal, que tem mais propósito com esse trabalho.
            </p>
            <p className="time-text mt-6 mb-6">
              O que mais me cativa na Theia é a possibilidade de atender dentro de uma equipe multi e alcançar mais mulheres, fazendo a diferença em suas vidas.
            </p>
            <p className="time-title">Especialidade:</p>
            <p className="time-text mb-6">Fisioterapeuta pélvica</p>
            <p className="time-title">
              Formação:
            </p>
            <p className="time-text">• Fisioterapeuta Obstétrica/Pélvica pela Universidade de São Paulo</p>
          </div>
          </Collapsible>
        </div>
      </section>
    </Layout>
  )
}

export default FisioterapeutaPelvica