import React, { useEffect, useRef, useState } from "react";
import { getKeyValue } from '../utils'
import './collapsible.scss'

import FontIcon from './atoms/fontIcon/FontIcon'

interface IProps {
  open?: boolean;
  header: string | React.ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  iconButtonClassName?: string;
  contentClassName?: string;
  contentContainerClassName?: string;
  collapsibleClassName?: string;
  changeHeader?: boolean;
  openedHeader?: string;
  variant?: any;
}

const mapVariant = {
  primary: 'primary',
  secondary: 'secondary',
  subtle: 'subtle',
  text: 'text'
}

const Collapsible: React.FC<IProps> = ({
  open,
  collapsibleClassName = "collapsible-card-edonec",
  headerClassName = "collapsible-header-edonec",
  titleClassName = "title-text-edonec",
  iconButtonClassName = "collapsible-icon-button-edonec",
  contentClassName = "collapsible-content-edonec",
  contentContainerClassName = "collapsible-content-padding-edonec",
  children,
  header,
  changeHeader,
  openedHeader,
  variant = 'primary',
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState<number | undefined>(
    open ? undefined : 0
  );
  const ref = useRef<HTMLDivElement>(null);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

 
  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);
  return (
    <>
      <div className={`${collapsibleClassName} ${getKeyValue(mapVariant, variant)}`} onClick={handleFilterOpening}>
        <div>
          <div className={headerClassName}>
            <div className={titleClassName}>{isOpen && changeHeader ? openedHeader : header}</div>
            <button
              type="button"
              className={iconButtonClassName}
            >
             {!isOpen ? (
                <FontIcon iconType="icon-Property-2Arrow---Down-2" color="#582CC7" fontSize="25px" />
              ) : (
                <FontIcon iconType="icon-Property-2Arrow---Up-2" color="#582CC7" fontSize="25px" />
              )}
            </button>
          </div>
        </div>
        <div className={contentClassName} style={{ height }}>
          <div ref={ref}>
            <div className={contentContainerClassName}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
